import styles from "./Login.module.scss"
import logo from "../../assets/images/MT5 TRADING VIEW LOGO.svg"
import React, {useContext, useState} from "react"
import {Auth} from "../../context/auth"

export const Login = () => {
    const contextAuth = useContext(Auth)

    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const isButtonDisabled = login.trim() === "" || password.trim() === ""

    return (
        <div className={styles.video}>
            <video
                className={styles.video__background}
                src={require("../../assets/images/TradeNow.mp4")}
                autoPlay
                muted
                loop
                playsInline
            >
            </video>
            <div className={styles.login}>
                <div>
                    <div className={styles.login__card + " bg-white p-5 shadow"}>
                        <img height={"68px"} src={logo} className={styles.login__logo + " rounded mx-auto d-block mb-5"}
                             alt="..."/>
                        <div className="mb-3">

                            <input type="number" onChange={(e) => setLogin(e.target.value)}
                                   className="form-control form-control-lg" placeholder="MT5 Account"/>
                        </div>
                        <div className="mb-5">

                            <input type="password" onChange={(e) => setPassword(e.target.value)}
                                   className="form-control form-control-lg" placeholder="MT5 Password"/>
                        </div>
                        <div className={styles.login__buttonContainer}>
                            <button type="button" disabled={isButtonDisabled}
                                    className={styles.login__button + " btn btn-primary form-control btn-lg"}
                                    onClick={() => contextAuth.login(
                                        +login,
                                        password
                                    )}>Log In
                            </button>
                        </div>
                        <div className={"text-center mt-5"}>
                            <div>Don't have a trading account yet?</div>
                            <div>
                                <a target="_blank" href="https://gtly.ink/ecgq3br0_?redirectUrl=%2F&amp;lang=en">Click
                                    here</a>
                                &nbsp; to register.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}