import {useContext, useEffect, useRef} from "react"
import {
    widget,
    ResolutionString, TradingTerminalWidgetOptions, TimeFrameItem, ThemeName, CustomThemes
} from "../../assets/lib/charting_library"
import * as React from "react"
import {getLanguageFromURL} from "../../assets/utils/language"
import {Config} from "../../context/config"
import {DataFeed} from "./libs/DataFeed"
import {Auth} from "../../context/auth"
import {getParameterByName} from "../../assets/utils/query"
import {dark, light} from "./utils/theme"

export const TradingViewWidget = () => {
    const contextAuth = useContext(Auth)
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>
    const config = useContext(Config)
    const getTheme = () => (localStorage.getItem("theme") || "light") as ThemeName

    useEffect(() => {
        const theme = getThemeFromUrlOrStorage()

        const widgetOptions: TradingTerminalWidgetOptions = {
            debug: false,
            container: chartContainerRef.current!,
            // @ts-ignore
            datafeed: DataFeed.getInstance(config, contextAuth),
            interval: "1" as ResolutionString,
            time_frames: [
                {text: "1D", resolution: "1"},
                {text: "5D", resolution: "5"},
                {text: "1M", resolution: "30"},
                {text: "3M", resolution: "60"}
            ] as TimeFrameItem[],
            // @ts-ignore
            timezone: userTimezone,
            symbol: "EURUSD",
            custom_css_url: "/charting_library_custom.css",
            library_path: "/charting_library/",
            locale: getLanguageFromURL(),
            autosize: true,
            load_last_chart: true,
            fullscreen: true,
            theme,
            enabled_features: [
                "watchlist_sections"
            ],
            disabled_features: [
                "order_panel",
                "trading_account_manager"
            ],
            widgetbar: {
                details: true,
                news: false,
                watchlist: true,
                datawindow: true,
                watchlist_settings: {
                    default_symbols: ["EURUSD", "EURGBP"],
                    readonly: false
                }
            },
            //@ts-ignore
            // broker_factory: function(host) { return new Brokers.BrokerSample(host, {}) },
            // broker_config: {
            //     configFlags: {
            //         supportNativeReversePosition: true,
            //         supportClosePosition: true,
            //         supportPLUpdate: true,
            //         supportLevel2Data: false,
            //         showQuantityInsteadOfAmount: true,
            //         supportEditAmount: false,
            //         supportOrderBrackets: true,
            //         supportMarketBrackets: true,
            //         supportPositionBrackets: true,
            //     },
            //     durations: [
            //         { name: 'DAY', value: 'DAY' },
            //         { name: 'GTC', value: 'GTC' },
            //     ],
            // },
            custom_themes: {
                light: light,
                dark: dark
            } as CustomThemes
        }
        const widgetInstance = new widget(widgetOptions)

        widgetInstance.onChartReady(() => {
            widgetInstance.changeTheme(theme).then(() => {
                widgetInstance.headerReady().then(() => {
                    widgetReadyLogout(widgetInstance)
                    widgetReadyThemeToggler(widgetInstance)
                })
            })
        })
        return () => {
            widgetInstance.remove()
        }
    }, [])

    const widgetReadyThemeToggler = (widget: any) => {
        const theme = getTheme()
        const el = widget.createButton({
            align: "right"
        })
        el.dataset.internalAllowKeyboardNavigation = "true"
        el.id = "theme-toggle"
        el.title = "Toggle Theme"
        el.innerHTML = `
            <div class="theme-switch-wrapper">
                <label for="theme-switch" id="theme-switch-label">Dark Mode</label>
                <div class="switchWrap-bl9AR3Gv">
                    <span class="switcher-fwE97QDf">
                        <input id="theme-switch" tabindex="-1" type="checkbox" class="input-fwE97QDf activeStylesEnabled-fwE97QDf">
                        <span class="thumbWrapper-fwE97QDf">
                            <span class="switchView-CtnpmPzP small-CtnpmPzP">
                                <span class="track-CtnpmPzP"></span>
                                <span class="thumb-CtnpmPzP"></span>
                            </span>
                        </span>
                    </span>
                </div>
            </div>
        `
        const checkboxEl = el.querySelector("#theme-switch")
        const switchViewEl = el.querySelector(".switchView-CtnpmPzP")

        checkboxEl.checked = theme === "dark"
        if (theme === "dark") switchViewEl.classList.add("checked-CtnpmPzP")

        checkboxEl.addEventListener("change", function () {
            const theme = getTheme()
            const themeToSet = theme === "dark" ? "light" : "dark"
            localStorage.setItem("theme", themeToSet)
            themeToSet === "dark"
                ? switchViewEl.classList.add("checked-CtnpmPzP")
                : switchViewEl.classList.remove("checked-CtnpmPzP")
            widget.changeTheme(themeToSet, {disableUndo: false})
        })
    }

    const widgetReadyLogout = (widgetInstance: any) => {
        const logoutButton = widgetInstance.createButton()
        logoutButton.setAttribute("title", "Click to logout")
        logoutButton.classList.add("apply-common-tooltip")
        logoutButton.addEventListener("click", () =>
            widgetInstance.showConfirmDialog({
                title: "Confirmation",
                body: "Are you sure you want to logout?",
                mainButtonText: "Logout",
                cancelButtonText: "Cancel",
                callback: (isApproved: any) => {
                    if (isApproved) contextAuth.logout()
                }
            })
        )
        logoutButton.innerHTML = "Logout"
    }

    const getThemeFromUrlOrStorage = (): ThemeName => {
        const removeThemeFromUrl = () => {
            const url = new URL(window.location.href)
            url.searchParams.delete("theme")
            window.history.replaceState({}, document.title, url.pathname + url.search)
        }

        const urlTheme = getParameterByName("theme") as ThemeName
        if (urlTheme && (urlTheme === "dark" || urlTheme === "light")) {
            localStorage.setItem("theme", urlTheme)
            removeThemeFromUrl()
            return urlTheme
        }

        return getTheme()
    }


    return (
        <div
            ref={chartContainerRef}
            className={"TradingViewWidget"}
        />
    )
}