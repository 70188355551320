const initBasic = {
    credentials: "include"
} as RequestInit

const headersStandard = {
    "Content-Type": "application/json"
}

const generateUUIDv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0
        const v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
    })
}

export const $fetch = {
    get: async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
        return await fetch(input, {
            ...init,
            ...initBasic,
            method: "GET",
            mode: "cors",
            headers: {
                ...init?.headers,
                ...headersStandard,
                "X-Trace-ID": generateUUIDv4()
            }
        })
    },
    post: async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
        return await fetch(input, {
            ...init,
            ...initBasic,
            method: "POST",
            mode: "cors",
            headers: {
                ...init?.headers,
                ...headersStandard,
                "X-Trace-ID": generateUUIDv4()
            }
        })
    }
}


