import React, { createContext, ReactNode } from 'react'


export interface IConfig {
    urlWebsocket: string
    urlRestAPI: string
    applicationTitle: string
}

export const defaultConfig: IConfig = {
    urlWebsocket: '',
    urlRestAPI: '',
    applicationTitle: ''
}

export const Config = createContext(defaultConfig)

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    const applicationTitle = process.env.REACT_APP_APPLICATION_TITLE || ''
    document.title = applicationTitle

    return (
        <Config.Provider value={ {
            urlWebsocket: process.env.REACT_APP_URL_WEBSOCKET,
            urlRestAPI: process.env.REACT_APP_URL_RESTAPI,
            applicationTitle
        } as IConfig }>
            { children }
        </Config.Provider>
    )
}
