const business = [
    "#f4ecfa","#e9d9f5","#dfc5ef","#d4b2ea","#c99fe5","#be8ce0","#b378da","#a865d5","#9e52d0","#7d18c0","#7d18c0","#7316b0","#6814a0","#5e1290","#531080","#490e70","#3f0c60","#340a50","#2a0840","#1f0630"
]

const red = [
    "#feefee","#fde0de","#fcd0cd","#fbc0bc","#fab1ab","#faa19b","#f9918a","#f88279","#f77268","#f66258","#f44336","#e03d32","#cb382d","#b73229","#a32d24","#8e2720","#7a221b","#661c17","#511612","#3d110e"
]

const green = [
    "#ebf6f4","#d8ecea","#c4e3df","#b1d9d4","#9dd0ca","#8ac7bf","#76bdb4","#62b4aa","#4faa9f","#3ba194","#148e7f","#128274","#11766a","#0f6b5f","#0d5f55","#0c534a","#0a4740","#083b35","#072f2a","#052420"
]

const blue = [
    "#edf6fe","#daeefd","#c8e5fc","#b5dcfb","#a3d3fa","#90cbf9","#7ec2f8","#6bb9f7","#59b0f6","#46a8f5","#2196f3","#1e8adf","#1c7dcb","#1971b6","#1664a2","#13588e","#114b7a","#0e3f65","#0b3251","#08263d"
]

const gray = [
    "#f4f4f4","#e9e9e9","#dedede","#d3d3d3","#c8c8c8","#bebebe","#b3b3b3","#a8a8a8","#9d9d9d","#929292","#7c7c7c","#727272","#676767","#5d5d5d","#535353","#484848","#3e3e3e","#292929","#292929","#1f1f1f"
]

const yellow = [
    "#fffdef","#fffcde","#ffface","#fff8be","#fff7ad","#fff59d","#fff38d","#fff27c","#fff06c","#ffee5c","#ffeb3b","#ead736","#d5c431","#bfb02c","#aa9d27","#958922","#80761e","#6a6219","#554e14","#403b0f"
]

const orange = [
    "#fff6ea","#ffeed5","#ffe5bf","#ffddaa","#ffd495","#ffcc80","#ffc36a","#ffba55","#ffb240","#ffa92b","#ff9800","#ea8b00","#d57f00","#bf7200","#aa6500","#955900","#804c00","#6a3f00","#553300","#402600"
]


export const light = {
    "color1": [
        ...business
    ],
    "color2": [
        ...gray
    ],
    "color3": [
        ...red
    ],
    "color4": [
        ...green
    ],
    "color5": [
        ...orange
    ],
    "color6": [
        ...blue
    ],
    "color7": [
        ...yellow
    ],
    "white": "#ffffff",
    "black": "#000000"
}

export const dark = {
    "color1": [
        ...business
    ],
    "color2": [
        ...gray
    ],
    "color3": [
        ...red
    ],
    "color4": [
        ...green
    ],
    "color5": [
        ...orange
    ],
    "color6": [
        ...blue
    ],
    "color7": [
        ...yellow
    ],
    "white": "#ffffff",
    "black": "#000000"
}









