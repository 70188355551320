import {
    Bar,
    DatafeedConfiguration,
    DatafeedErrorCallback,
    HistoryCallback,
    LibrarySymbolInfo,
    PeriodParams,
    ResolutionString,
    SearchSymbolResultItem,
    SubscribeBarsCallback,
    SymbolResolveExtension
} from "../../../assets/lib/charting_library"
import {
    IDatafeedChartApi,
    IDatafeedQuotesApi,
    IExternalDatafeed, QuoteOkData,
    QuotesCallback,
    QuotesErrorCallback
} from "../../../assets/lib/charting_library/datafeed-api"
import { defaultConfig, IConfig } from "../../../context/config"
import { IAuth } from "../../../context/auth"
import { $fetch } from "../../../assets/utils/fetch"
import { WebSocketsTicksManager } from "./WebSocketsTicksManager"

export class DataFeed implements IExternalDatafeed,
    IDatafeedQuotesApi,
    IDatafeedChartApi {
    private readonly contextAuth: IAuth
    private static instance: DataFeed | null = null
    private symbols: LibrarySymbolInfo[] = []
    private readonly applicationConfiguration: IConfig = defaultConfig
    private configuration: DatafeedConfiguration = {
        // TODO: here is full data feed configuration
        // supported_resolutions: ["1", "5", "15", "30", "60", "240", "1D", "1W", "1M"] as ResolutionString[],
        //Temp for V1
        supported_resolutions: ["1", "5", "15", "30", "60", "240"] as ResolutionString[],
        exchanges: [{ value: "TradeQuoMarkets", name: "TradeQuoMarkets", desc: "TradeQuoMarkets LTD" }],
        symbols_types: [],
        supports_marks: true,
        supports_timescale_marks: true,
        supports_time: false
    }
    resolutionMapper: Record<string, number> = {
        "1": 1,
        "5": 5,
        "15": 15,
        "30": 30,
        "60": 60,
        "240": 240,
        "1D": 1440,
        "1W": 10080,
        "1M": 43200
    }
    private quoteState: Map<string, QuoteOkData> = new Map()
    private lastBarsCache: Map<string, Map<string, Bar>> = new Map()
    private subscribers: Map<string, {
        symbolInfo: LibrarySymbolInfo;
        resolution: string;
        onRealtimeCallback: SubscribeBarsCallback;
    }> = new Map()
    private quoteSubscribers: Map<string, {
        symbols: string[];
        fastSymbols: string[];
        onRealtimeCallback: QuotesCallback;
    }> = new Map()

    constructor(applicationConfiguration: IConfig = defaultConfig, contextAuth: IAuth) {
        this.applicationConfiguration = applicationConfiguration
        this.contextAuth = contextAuth
    }

    static getInstance(applicationConfiguration: IConfig = defaultConfig, contextAuth: IAuth): DataFeed {
        if (!DataFeed.instance) {
            DataFeed.instance = new DataFeed(applicationConfiguration, contextAuth)
        }
        return DataFeed.instance
    }

    async getGroups() {
        try {
            const { loginAccountId } = this.contextAuth
            if (!loginAccountId) return []
            const response = await $fetch.get(`${this.applicationConfiguration.urlRestAPI}/api/v1/groups?login=${loginAccountId}`)
            const data = await response.json()
            return extendedGroups(data)
        } catch (error) {
            return extendedGroups()
        }

        function extendedGroups(groups = []) {
            return [{ name: "All", value: "" }, ...groups.map(i => ({ name: i, value: i }))]
        }
    }

    async getSymbols() {
        try {
            const { loginAccountId } = this.contextAuth
            if (!loginAccountId) return []
            const response = await $fetch.get(`${this.applicationConfiguration.urlRestAPI}/api/v1/symbols?login=${loginAccountId}`)
            let nativeSymbols: any = await response.json() || []
            return nativeSymbols.map((i: any) => {
                return {
                    name: i.symbol,
                    ticker: i.symbol,
                    description: i.description,
                    type: i.group,
                    exchange: "TradeQuoMarkets",
                    timezone: "Etc/UTC",
                    listed_exchange: "TradeQuoMarkets",
                    has_intraday: true,
                    has_daily: true,
                    has_weekly_and_monthly: true,
                    supported_resolutions: this.configuration.supported_resolutions as ResolutionString[],
                    data_status: "streaming",
                    // volume_precision: 2,
                    session: "24x7",
                    minmov: 1,
                    pricescale: Math.pow(10, i.digits)
                }
            })
        } catch (error) {
            console.error("[getSymbols]:", error)
        }
    }

    async onReady(callback: (config: DatafeedConfiguration) => void): Promise<void> {
        this.symbols = await this.getSymbols()
        this.configuration.symbols_types = await this.getGroups()
        callback(this.configuration)
        const webSocketsTicksManagerAPI = WebSocketsTicksManager.getInstance(this.applicationConfiguration)
        webSocketsTicksManagerAPI.subscribe(this.onMessageUpdateBar.bind(this))
        webSocketsTicksManagerAPI.subscribe(this.onMessageUpdateQuote.bind(this))
    }

    resolveSymbol(
        symbolId: string,
        onResolve: (symbolInfo: LibrarySymbolInfo) => void,
        onError: (err: string) => void,
        extension?: SymbolResolveExtension
    ): void {
        let symbol = this.symbols.find((symbol) => symbol.name === symbolId) as LibrarySymbolInfo
        setTimeout(() => onResolve(symbol), 0)
    }

    searchSymbols(
        userInput: string,
        exchange: string,
        symbolType: string,
        onResult: (items: SearchSymbolResultItem[]) => void
    ): void {
        const symbols = this.symbols.filter((symbol) => {
            const matchesName = (
                symbol.name.toLowerCase().includes(userInput.toLowerCase())
                || symbol.description.toLowerCase().includes(userInput.toLowerCase())
            )
            const matchesType = symbolType === "" || symbol.type === symbolType
            // const matchesExchange = exchange === '' || symbol.exchange === exchange
            return matchesName
                // && matchesExchange
                && matchesType
        }).map(symbol => ({
            symbol: symbol.name,
            description: symbol.description,
            exchange: symbol.exchange,
            ticker: symbol.ticker,
            type: symbol.type
        }))

        setTimeout(() => onResult(symbols), 0)
    }

    async getBars(
        symbolInfo: LibrarySymbolInfo,
        resolution: ResolutionString,
        periodParams: PeriodParams,
        onResult: HistoryCallback,
        onError: DatafeedErrorCallback
    ): Promise<void> {
        const { from, to, firstDataRequest } = periodParams
        try {
            let bars = []
            const data = await this.fetchBars({ symbol: symbolInfo.name, from, to, resolution })
            if (data && data.length) {
                bars = data
                if (firstDataRequest) {
                    if (!this.lastBarsCache.has(symbolInfo.name)) this.lastBarsCache.set(symbolInfo.name, new Map())
                    this.lastBarsCache.get(symbolInfo.name)!.set(resolution, { ...bars[bars.length - 1] })
                }
            }
            onResult(bars, { noData: !bars.length })
        } catch (error) {
            onError(JSON.stringify(error))
        }
    }

    async getQuotes(symbols: string[], onDataCallback: QuotesCallback, onErrorCallback: QuotesErrorCallback): Promise<void> {

            let quotesPromises = symbols.map(async (symbol) => {
                const symbolInfo: LibrarySymbolInfo = this.symbols.find(s => s.name === symbol)!

                const now = new Date()
                const fromDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()))
                const from = fromDate.getTime() / 1000
                const to = Math.floor(Date.now() / 1000)

                const bars = await this.fetchBars({ symbol, from, to, resolution: "1" })
                if (!bars || bars.length === 0)  return null
                
                const latestBar = bars[bars.length - 1]
                const firstBar = bars[0]

                const lp = latestBar.close
                const openPrice = firstBar.open

                let prevDay = new Date(fromDate.getTime())
                prevDay = symbolInfo.type === "Cryptos" ? new Date(prevDay.setDate(prevDay.getDate() - 1)) : getPreviousTradingDay(prevDay)

                const prevFromDate = new Date(prevDay)
                prevFromDate.setUTCHours(0, 0, 0, 0)
                const prevFrom = prevFromDate.getTime() / 1000

                const prevToDate = new Date(prevDay)
                prevToDate.setUTCHours(23, 59, 59, 999)
                const prevTo = prevToDate.getTime() / 1000

                const prevBars = await this.fetchBars({ symbol, from: prevFrom, to: prevTo, resolution: "1" })
                let prevClosePrice = prevBars && prevBars.length > 0 ? prevBars[prevBars.length - 1].close : openPrice

                const ch = lp - prevClosePrice
                const chp = (ch / prevClosePrice) * 100

                const quoteData: QuoteOkData = {
                    n: symbol,
                    s: "ok",
                    v: {
                        ch,
                        chp,
                        short_name: symbol,
                        exchange: "TradeQuoMarkets",
                        original_name: symbol,
                        description: this.symbols.find(s => s.name === symbol)?.description || "",
                        lp,
                        ask: lp,
                        bid: lp,
                        open_price: openPrice,
                        high_price: Math.max(...bars.map((bar: any) => bar.high)),
                        low_price: Math.min(...bars.map((bar: any) => bar.low)),
                        prev_close_price: prevClosePrice,
                        volume: bars.reduce((sum: number, bar: any) => sum + bar.volume, 0)
                    }
                }

                this.quoteState.set(symbol, quoteData)
                return quoteData
            })

            const results = await Promise.all(quotesPromises)
            const data = results.filter((result): result is QuoteOkData => result !== null)
            onDataCallback(data)


        function getPreviousTradingDay(date: Date): Date {
            const dayOfWeek = date.getUTCDay();
            const daysToSubtract = dayOfWeek === 1 ? 3 : dayOfWeek === 0 ? 2 : 1;
            const previousDate = new Date(date);
            previousDate.setDate(previousDate.getDate() - daysToSubtract);
            return previousDate;
        }
    }

    subscribeBars(
        symbolInfo: LibrarySymbolInfo,
        resolution: ResolutionString,
        onTick: SubscribeBarsCallback,
        listenerGuid: string,
        onResetCacheNeededCallback: () => void
    ): void {
        this.subscribers.set(listenerGuid, {
            symbolInfo,
            resolution,
            onRealtimeCallback: onTick
        })
    }

    subscribeQuotes(
        symbols: string[],
        fastSymbols: string[],
        onRealtimeCallback: QuotesCallback,
        listenerGUID: string
    ): void {
        this.quoteSubscribers.set(listenerGUID, {
            symbols,
            fastSymbols,
            onRealtimeCallback
        })
    }

    unsubscribeQuotes(listenerGUID: string): void {
        const subscription = this.quoteSubscribers.get(listenerGUID)
        if (subscription) this.quoteSubscribers.delete(listenerGUID)
    }

    unsubscribeBars(listenerGuid: string): void {
        const subscriber = this.subscribers.get(listenerGuid)
        if (subscriber) this.subscribers.delete(listenerGuid)
    }

    private async fetchBars(params: { symbol: string, from: number, to: number, resolution: string }) {
        const { symbol, from, to, resolution } = params
        const query = `timeframe=${this.resolutionMapper[resolution]}&from_time=${from}&to_time=${to}`
        try {
            const response = await $fetch.get(
                `${this.applicationConfiguration.urlRestAPI}/api/v1/symbols/${encodeURIComponent(symbol)}/charts?${query}`
        )
            return await response.json()
        } catch (error) {
            console.error("[fetchBars]:", error)
        }
    }

    private onMessageUpdateQuote(data: any): void {
        const { symbol, bid, ask, volume } = data

        const existingQuote = this.quoteState.get(symbol)
        if (!existingQuote) return

        const quoteValues = existingQuote.v
        const prevClosePrice = quoteValues.prev_close_price || quoteValues.open_price || bid

        quoteValues.lp = bid;
        quoteValues.ask = ask;
        quoteValues.bid = bid;
        quoteValues.volume = volume;
        quoteValues.high_price = Math.max(quoteValues.high_price || bid, bid);
        quoteValues.low_price = Math.min(quoteValues.low_price || bid, bid);
        quoteValues.ch = (quoteValues.lp || 0) - prevClosePrice
        quoteValues.chp = quoteValues.ch ? (quoteValues.ch / prevClosePrice) * 100 : 0

        this.quoteState.set(symbol, existingQuote)
        
        for (const { symbols, fastSymbols, onRealtimeCallback } of Array.from(this.quoteSubscribers.values())) {
            if (symbols.includes(symbol) || fastSymbols.includes(symbol)) {
                onRealtimeCallback([existingQuote]) 
            }
        }
    }

    private onMessageUpdateBar(data: any): void {
        const { symbol, timestamp, bid, volume } = data
        const tradePrice = bid
        const tradeTime = timestamp

        for (const subscriptionItem of Array.from(this.subscribers.values())) {
            if (subscriptionItem.symbolInfo.name === symbol) {
                const resolution = subscriptionItem.resolution
                const lastBarsForSymbol = this.lastBarsCache.get(symbol) || new Map()
                const lastBar = lastBarsForSymbol.get(resolution) || {
                    time: tradeTime,
                    open: tradePrice,
                    high: tradePrice,
                    low: tradePrice,
                    close: tradePrice,
                    volume: volume
                }
                const nextBarTime = this.getNextBarTime(lastBar.time, resolution)

                let updatedBar
                if (tradeTime >= nextBarTime) {
                    updatedBar = {
                        time: nextBarTime,
                        open: tradePrice,
                        high: tradePrice,
                        low: tradePrice,
                        close: tradePrice,
                        volume: volume
                    }
                } else {
                    updatedBar = {
                        ...lastBar,
                        high: Math.max(lastBar.high, tradePrice),
                        low: Math.min(lastBar.low, tradePrice),
                        close: tradePrice,
                        volume: lastBar.volume + volume
                    }
                }

                lastBarsForSymbol.set(resolution, updatedBar)
                this.lastBarsCache.set(symbol, lastBarsForSymbol)
                subscriptionItem.onRealtimeCallback(updatedBar as Bar)
                document.title = `${updatedBar.close} | ${symbol} | ${this.applicationConfiguration.applicationTitle}`
            }
        }
    }

    private resolutionToMilliseconds(resolution: ResolutionString): number {
        const resolutionNumber = parseInt(resolution)
        if (!isNaN(resolutionNumber)) return resolutionNumber * 60 * 1000
        else if (resolution === "D" || resolution === "1D") return 24 * 60 * 60 * 1000
        else if (resolution === "W" || resolution === "1W") return 7 * 24 * 60 * 60 * 1000
        else if (resolution === "M" || resolution === "1M") return 30 * 24 * 60 * 60 * 1000
        else return 60 * 1000
    }

    private getNextBarTime(currentTime: number, resolution: string): number {
        const intervalMs = this.resolutionToMilliseconds(resolution as ResolutionString)
        return currentTime + intervalMs
    }
}

