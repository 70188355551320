import { defaultConfig, IConfig } from "../../../context/config"

export class WebSocketsTicksManager {
    private socket: WebSocket | null = null
    private socketReconnectAttempts: number = 0
    private socketReconnectTimeoutId: number | null = null;

    private applicationConfiguration: IConfig = defaultConfig
    private static instance: WebSocketsTicksManager | null = null

    private subscribers: Set<Function> = new Set();
    private messageBuffer: any[] = [];
    private MAX_BUFFER_SIZE = 500

    constructor(applicationConfiguration: IConfig = defaultConfig) {
        this.applicationConfiguration = applicationConfiguration
        this.connect()
        this.startNotificationLoop();
    }

    static getInstance(applicationConfiguration: IConfig = defaultConfig): WebSocketsTicksManager {
        if (!WebSocketsTicksManager.instance) {
            WebSocketsTicksManager.instance = new WebSocketsTicksManager(applicationConfiguration)
        }
        return WebSocketsTicksManager.instance
    }

    public subscribe(subscriber: Function): void {
        this.subscribers.add(subscriber);
    }

    public unsubscribe(subscriber: Function): void {
        this.subscribers.delete(subscriber);
    }

    private startNotificationLoop() {
        const notify = () => {
            this.notifySubscribers()
            setTimeout(notify, 1000 / 60)
        };
        setTimeout(notify, 1000 / 60)
    }

    private notifySubscribers(): void {
        if (!this.messageBuffer.length) return;
        for (const message of this.messageBuffer) {
            for (const subscriber of Array.from(this.subscribers)) {
                subscriber(message);
            }
        }
        this.messageBuffer = []
    }

    private connect(): void {
        if (this.socket) return

        this.socket = new WebSocket(`${this.applicationConfiguration.urlWebsocket}/api/v1/symbols/stream` as string)

        this.socket.onopen = () => {
            this.socketReconnectAttempts = 0
        }

        this.socket.onmessage = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            if (this.subscribers.size === 0) return;
            if (this.messageBuffer.length > this.MAX_BUFFER_SIZE) this.messageBuffer.shift()
            this.messageBuffer.push(data)
        }

        this.socket.onclose = () => {
            this.socket = null;
            if (this.socketReconnectAttempts < 10) {
                if (this.socketReconnectTimeoutId) {
                    clearTimeout(this.socketReconnectTimeoutId);
                }
                this.socketReconnectTimeoutId = window.setTimeout(() => this.connect(), 3000);
            }
            this.socketReconnectAttempts++;
        };

        this.socket.onerror = (error) => {
            console.error(error)
            this.socket = null
        }
    }
}