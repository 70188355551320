import React from "react"
import {ConfigProvider} from "./context/config"
import {AuthProvider} from "./context/auth"
import {TradingViewWidget} from "./components/tradingView"
import {ToastContainer} from "react-toastify"

function App() {
    return (
        <ConfigProvider>
            <ToastContainer/>
            <AuthProvider>
                <TradingViewWidget/>
            </AuthProvider>
        </ConfigProvider>
    )
}

export default App
